/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./assets/scss/flex";
@import "./assets/fontawesome/css/all.css";
@import "~animate.css/animate.css";
@import "~quill/dist/quill.snow.css";
@import '~tippy.js/dist/tippy.css';
@import "./assets/scss/style";
@import "~toastr/toastr.scss";
@import "./theme/dark.scss";
@import "./theme/purple.scss";
@import '~intl-tel-input/build/css/intlTelInput.css';


 
  ion-card {border-radius: 14px;
  

  }
   

  video{
    border-radius: 15px; 
  }

  body{background:var(--ion-color-tertiary)}

  .joyride-step__container {
    box-sizing: border-box;
    position: relative;
    color: #000;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 0 30px 1px #000;
    border-radius: 10px;
}

.folder-button{
  cursor: pointer;
}

ion-spinner {
  
  height: 13px;
}



.ql-editor {
  min-height:200px;

  &.fix-min-height {
    min-height: unset;
  }
}


#zmmtg-root {
  display: none;
}

html, body {
  min-width: unset !important;
}

// custom zoom
.security-option-menu__pop-menu, .audio-option-menu, .video-option-menu, .more-button, .sharing-setting-dropdown-menu-container.dropup.btn-group {
  display: none !important;
}

ion-item {
  --highlight-color-valid: var(--ion-color-tertiary);
  --highlight-color-invalid:var(--ion-color-danger);
  --highlight-color-focused: var(--ion-color-tertiary);
}
ion-input input[type=text]:focus {
  outline:none !important; 
}

ion-input input[type=text] {
  outline:none !important; 
}
 


.my-custom-alert .alert-wrapper {
  min-width: 450px !important;
}