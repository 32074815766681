.text-center {
  text-align: center;
}

a {
  cursor: pointer;
}

.text-error {
  color: red;
}

.ml-1 {
  margin-left: 4px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-4 {
  margin-right: 16px;
}

.mb-2 {
  margin-bottom: 8px;
}

.ml-3 {
  margin-left: 16px;
}

.mt-2 {
  margin-top: 16px;
}

.bold {
  font-weight: 600;
}

.full-size {
  width: 100%;
  height: 100%;
}

.html-styling {
  pre {
    color: #fff;
    background-color: #1e293b;
    overflow-x: auto;
    font-size: 1em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: 0.25rem;
    padding: 0.6666667em 1em;
  }

  blockquote {
    font-weight: 500;
    font-style: italic;
    color: var(--fuse-text-default);
    border-left-width: 0.25rem;
    border-left-color: var(--fuse-border);
    quotes: "\201C""\201D""\2018""\2019";
    margin: 0;
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em;
    border-left: 4px solid rgb(226, 232, 240);
  }

  p {
    line-height: 1.6;
  }

  ul {
    margin: 0;
  }
}

.swiper-slide {
  text-align: unset;
}

.hide-visible {
  visibility: hidden;
}

.pointer {
  cursor: pointer;
}

ion-modal {
  --width: 95%;
  --height: 95%;
}

.modal-wrapper {
  border-radius: 20px;
}

#onesignal-bell-launcher {
  bottom: 90px !important;
}

body.hide-noti {
  #onesignal-bell-container {
    display: none;
  }
}
.video-alert {
  position: fixed !important;
}


.gauges-container {
  display: flex;
}
/* ------ Default Style ---------- */
mwl-gauge {
  flex: 1;
  display: block;
  padding: 10px;
  background-color: #222;
  margin: 7px;
  border-radius: 3px;
}
mwl-gauge > .gauge > .dial {
  stroke: #334455;
  stroke-width: 2;
  fill: rgba(0, 0, 0, 0);
}
mwl-gauge > .gauge > .value {
  stroke: rgb(47, 227, 255);
  stroke-width: 2;
  fill: rgba(0, 0, 0, 0);
}
mwl-gauge > .gauge > .value-text {
  fill: rgb(47, 227, 255);
  font-family: sans-serif;
  font-weight: bold;
  font-size: 0.8em;
}
/* ------- Alternate Style ------- */
mwl-gauge.two {
  display:inline
}
mwl-gauge.two > .gauge > .dial {
  stroke: #334455;
  stroke-width: 10;
}
mwl-gauge.two > .gauge > .value {
  stroke: orange;
  stroke-dasharray: none;
  stroke-width: 13;
}
mwl-gauge.two > .gauge > .value-text {
  fill: orange;
}
/* ------- Alternate Style ------- */
mwl-gauge.three {
  background: none;
}
mwl-gauge.three > .gauge > .dial {
  stroke: #fff;
  stroke-width: 13;
}
mwl-gauge.three > .gauge > .value {
  stroke: #c9de3c;
  stroke-width: 13;
}
mwl-gauge.three > .gauge > .value-text {
  fill: #c9de3c;
  font-size: 28px;
}
/* ----- Alternate Style ----- */
mwl-gauge.four > .gauge > .dial {
  stroke: #334455;
  stroke-width: 5;
}
mwl-gauge.four > .gauge > .value {
  stroke: #be80ff;
  stroke-dasharray: none;
  stroke-width: 5;
}
mwl-gauge.four > .gauge > .value-text {
  fill: #be80ff;
}
/* ----- Alternate Style ----- */
mwl-gauge.five > .gauge > .dial {
  stroke: #334455;
  stroke-width: 5;
}
mwl-gauge.five > .gauge > .value {
  stroke: #f8774b;
  stroke-dasharray: 25 1;
  stroke-width: 5;
}
mwl-gauge.five > .gauge > .value-text {
  fill: #f8774b;
  font-size: 0.7em;
}
/* ----- Alternate Style ----- */
mwl-gauge.six > .gauge > .dial {
  stroke: #334455;
  fill: #334455;
  stroke-width: 20;
}
mwl-gauge.six > .gauge > .value {
  stroke: #ff6daf;
  stroke-width: 20;
}
mwl-gauge.six > .gauge > .value-text {
  fill: #ff6daf;
  font-size: 0.7em;
}
mwl-gauge.seven > .gauge > .dial {
  stroke: transparent;
  stroke-width: 5;
  transform: scale(0.9, 0.9) translate3d(5.5px, 5.5px, 0);
  fill: rgba(148, 112, 57, 0.42);
}
mwl-gauge.seven > .gauge > .value {
  stroke: #f8774b;
  stroke-dasharray: none;
  stroke-width: 5;
}

/* ------- Alternate Style ------- */
mwl-gauge.ten {
  background: none;
}
mwl-gauge.ten > .gauge > .dial {
  stroke: #e2e2e2;
  stroke-width: 13;
}
mwl-gauge.ten > .gauge > .value {
  stroke: var(--ion-color-success);
  stroke-width: 13;
}
mwl-gauge.ten > .gauge > .value-text {
  fill: #056078;
  font-size: 28px;
}

mwl-gauge.tenRed {
  background: none;
}
mwl-gauge.tenRed > .gauge > .dial {
  stroke: #e2e2e2;
  stroke-width: 13;
}
mwl-gauge.tenRed > .gauge > .value {
  stroke: var(--ion-color-danger);
  stroke-width: 13;
}
mwl-gauge.tenRed > .gauge > .value-text {
  fill: #056078;
  font-size: 28px;
}


mwl-gauge.tenWarn {
  background: none;
}
mwl-gauge.tenWarn > .gauge > .dial {
  stroke: #e2e2e2;
  stroke-width: 13;
}
mwl-gauge.tenWarn > .gauge > .value {
  stroke: var(--ion-color-warning);
  stroke-width: 13;
}
mwl-gauge.tenWarn > .gauge > .value-text {
  fill: #056078;
  font-size: 28px;
}




hr {
background-color:#e2e2e2;
}

/*fix for some reason without this the inputs have broken styling*/
input[type=text]:focus {
  outline:10px  solid #000 !important;
  outline-offset: 1px !important;
}
 

.fr-wrapper {
  min-height: 250px;
}

.fr-view {
  color: #414141;
}

.vjs-watermark-content {
  font-size: 16px;
  color: #fff;
  opacity: 1 !important;
  font-weight: bold;
}

.ticket-modal-support {
  .fr-wrapper {
    min-height: 150px;
  }
  p {
    font-size: 16px !important;
  }

  ion-card-title {
    font-size: 16px !important;
  }
}

/* Akaal Creatives */
.aka-datepicker-modal-style {
  // border: 2px dashed #009fcb;
  isolation: isolate;
  // box-shadow: 0 0 30px inset #009fcb;
  // background: #000b;
  padding: 3rem;
}
.aka-quiz-hint {
  white-space: pre-wrap;
}
.aka-edit-quiz-hint-area {
  border: 1px solid #0001;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.aka-edit-quiz-hint-area textarea {
  min-height: 10rem;
}